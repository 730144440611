.statistics_group {
  margin-bottom: 1em;
}
.stat_divider {
  border-right: 1px solid #c7d5d8;
}
.ui.statistic.otn_dark_blue > .value,
.ui.statistic.stat_divider.otn_dark_blue > .value {
  color: #123466 !important;
}
.ui.statistic.otn_mid_blue > .value,
.ui.statistic.stat_divider.otn_mid_blue > .value {
  color: #0d5c63 !important;
}
.ui.statistic.otn_light_blue > .value,
.ui.statistic.stat_divider.otn_light_blue > .value {
  color: #23a8b8 !important;
}
.ui.statistic.otn_green > .value,
.ui.statistic.stat_divider.otn_green > .value {
  color: #09BC8A !important;
}
.ui.statistic.otn_yellow > .value,
.ui.statistic.stat_divider.otn_yellow > .value {
  color: #fec601 !important;
}
.ui.statistic.otn_orange > .value,
.ui.statistic.stat_divider.otn_orange > .value {
  color: #d35d3d !important;
}
.ui.statistic.otn_red .value,
.ui.statistic.otn_red > .value,
.ui.statistic.stat_divider.otn_red > .value {
  color: #d63232 !important;
}
