/* Despite best practices saying to use !important as
  little as possible semantic ui and plone both override
  the style you put in with like 6 things and it ends up
  needing to be used.
*/
.black-text {
  color: black !important;
}
.hint-text {
  font-size: medium;
  font-style: italic !important;
}
.text-area-border {
  border: 1px solid #c7d5d8 !important;
  border-radius: 0.22222222rem !important;
  padding: 5px !important;
}
.button-div {
  margin-top: 10px !important;
}
.form-input {
  margin-top: 10px !important;
}
.content-divider {
  margin-top: 15px !important;
}
.input-text {
  padding: 5px !important;
}
